import React, {
  useRef, useState, useEffect,
} from 'react';
import {
  SimpleShowLayout, TextField, useNotify, useShowController
} from 'react-admin';

import RestrictedShow from '../../components/admin/RestrictedShow';
import LabeledTextField from '../../components/fields/LabeledTextField';
import FormatValueField from '../../components/fields/FormatValueField';
import AisErpTitle from './AisErpTitle';
import styles from './AisErpShow.module.css';
import moment from 'moment';

import { Role, DISPLAY_DATETIME_FORMAT } from '../../constants';
import { getRole } from '../../storages/auth';
import { RoutePath, getUrl } from '../../routes';

const AisErpShow = (props) => {
  const { record } = useShowController(props);
  const permission = (getRole() === Role.ADMIN) || (getRole() === Role.DISPATCHER);
  const { showNotification } = { props };
  const formatDisplayDateTime = (date) => {
    return date ? moment(date).format(DISPLAY_DATETIME_FORMAT) : '';
}

  return (
    <RestrictedShow
      title={<AisErpTitleWithControls showNotification={showNotification} permission={permission} />}
      {...props}
    >
      <SimpleShowLayout>
        {record && record.organization && (
          <LabeledTextField
            label="telemedic.aisErpIntegration.show.organization"
            source="organization.name"
            record={record}
            url={getUrl(RoutePath.ORGANIZATION_SHOW, { id: record.organization_id })}
          />
        )}
        {record && record.terminal && (
          <LabeledTextField
            label="telemedic.aisErpIntegration.show.terminal"
            source="terminal.name"
            record={record}
            url={getUrl(RoutePath.TERMINAL_SHOW, { id: record.terminal_id })}
          />
        )}
        <TextField
          label="telemedic.aisErpIntegration.show.aisErpTerminalId"
          source="ais_erp_terminal_id"
        />
        <TextField
          label="telemedic.aisErpIntegration.show.aisErpTerminalTypeId"
          source="ais_erp_terminal_type_id"
        />
        {record && record.checkup_id && (
          <LabeledTextField
            label="telemedic.aisErpIntegration.show.checkup"
            source="checkup_id"
            record={record}
            url={getUrl(RoutePath.CHECKUP_SHOW, { id: record.checkup_id })}
          />
        )}
        <TextField
          label="telemedic.aisErpIntegration.show.aisErpCheckupId"
          source="ais_erp_checkup_id"
        />
        <FormatValueField
          label="telemedic.aisErpIntegration.show.createdAt"
          source="created_at"
          formatter={formatDisplayDateTime}
        />
        <FormatValueField
          label="telemedic.aisErpIntegration.show.datetimeSend"
          source="datetime_send"
          formatter={formatDisplayDateTime}
        />
        <TextField
          label="telemedic.aisErpIntegration.show.status"
          source="status"
        />
        <TextField
          label="telemedic.aisErpIntegration.show.retry"
          source="retry"
        />
        <TextField
          label="telemedic.aisErpIntegration.show.error"
          source="error"
        />
      </SimpleShowLayout>
    </RestrictedShow>
  );
};

const AisErpTitleWithControls = ({ record }) => {
  const iframeRef = useRef(null);

  const notify = useNotify();
  const [notice, setNotice] = useState();
  const [noticeType, setNoticeType] = useState();

  useEffect(() => {
    if (notice && noticeType) {
      notify(notice, noticeType);
      setNotice();
      setNoticeType();
    }
  }, [notice, noticeType, notify]);

  return (
    <>
      <AisErpTitle record={record} />
      <iframe
        className={styles.iframe}
        ref={iframeRef}
        title="print"
      />
    </>
  );
};

export default AisErpShow;